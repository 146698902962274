<template>
  <div>
    <advertisement-top-banner />
    <div class="m-0">
      <template v-if="$options.components['advertisement-loading']">
        <advertisement-loading v-if="isLoading" />
      </template>
    </div>
    <div else>
      <sections-slider
        :list="itemSuggestions($route.params.market)"
        subTitle="Suggested For You"
        :hasBackground="true"
        :showTitle="true"
        :hasButton="true"
        :withSlider="true"
        :viewMorePage="{
          name: `market-listing`,
          params: {
            items: `Suggested For You`,
          },
        }"
        class="suggested-slider market-suggested"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>

      <sections-slider
        :list="trendingItems($route.params.market)"
        subTitle="What's Trending"
        :hasBackground="true"
        :hasButton="true"
        :showTitle="true"
        :withSlider="true"
        class="suggested-slider market-suggested"
        :viewMorePage="{
          name: `market-listing`,
          params: {
            items: `What's Trending`,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "market-view",
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
    }),
    ...mapGetters({
      itemSuggestions: "market/getItemSuggestions",
      trendingItems: "market/getTrendingItems",
    }),
  },
  async mounted() {
    this.isLoading = true;
    const market = {
      path: this.$route.params.market,
    };
    localStorage.setItem("path", this.$route.params.market);
    await this.$store.dispatch("market/fetchAllItems", market);
    await this.$store.dispatch("market/fetchMarketList", market);
    this.isLoading = false;
  },
};
</script>
<style scoped>
.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}
.suggested-slider .suggester-div:hover {
  border: 1px solid #272727;
  box-shadow: 0px 8px 15px 0px rgb(0 0 0 / 10%);
}
.market-suggested .section-slider-head {
  margin-bottom: 1rem !important;
}
</style>
