var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('advertisement-top-banner'), _c('div', {
    staticClass: "m-0"
  }, [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e()], 2), _c('div', {
    attrs: {
      "else": ""
    }
  }, [_c('sections-slider', {
    staticClass: "suggested-slider market-suggested",
    attrs: {
      "list": _vm.itemSuggestions(_vm.$route.params.market),
      "subTitle": "Suggested For You",
      "hasBackground": true,
      "showTitle": true,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "market-listing",
        params: {
          items: "Suggested For You"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  }), _c('sections-slider', {
    staticClass: "suggested-slider market-suggested",
    attrs: {
      "list": _vm.trendingItems(_vm.$route.params.market),
      "subTitle": "What's Trending",
      "hasBackground": true,
      "hasButton": true,
      "showTitle": true,
      "withSlider": true,
      "viewMorePage": {
        name: "market-listing",
        params: {
          items: "What's Trending"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }